import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
 * @class
 * @param  {String} landingApplicationId
 */
export class Reject extends Service {
  constructor(creditApplicationId) {
    super()
    this._creditApplicationId = `${creditApplicationId}`
  }

  get data() {
      return {
          creditApplicationId: `${this._creditApplicationId}`
      }
  }

    /**
       * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
       */
     send() {
        return this._requestWrapper(LandingV4Instance({
          method: 'PUT',
          url: '/customer/reject',
          params: {
            creditApplicationId: this._creditApplicationId
          }
        }))
    }

}