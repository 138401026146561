import React from "react"

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import TuProceso from "../components/pages/TuProceso"
import GlobalFooterProvider from "../context/GlobalStateFooterContext";

const TuProcesoPage = ({ pageContext, location }) => (
  <GlobalFooterProvider>
    <LayoutsComponent
      broker={pageContext.broker}
      layout={pageContext.layout}
      companyName={pageContext.company}
      pageContext={pageContext}
      location={location}
      render={() => (
        <>
          <SEO title={pageContext.title} />
          <TuProceso pageContext={pageContext} />
        </>
      )}
    />
  </GlobalFooterProvider>
)

export default TuProcesoPage